import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'How to Develop a Business Plan for Your DPC Practice';
    const desc = `Follow this guide to build a business plan for your direct primary care practice.`;
    return (
      <TodoSubList title={title} desc={desc} id="business">
        <Head title={title} description={desc} url="/todo/business/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
